import type * as cheerio from 'cheerio';
import type {AppLoadContext} from '@remix-run/server-runtime';

import type {
  BLOG_PAGE_TYPE,
  Article,
  ArticleMetafield as ArticleMetafieldBlog,
} from '@/components/blog/types';
import type {Site} from '@data/types';
import type {loadRequestData} from '@/utils/server/request.server';
import type {
  Topics,
  ArticleMetafield,
  MetafieldEdges,
} from '@/pages/shopify.com/($locale)/enterprise/blog/types';
import {filterArticleTag} from '@/components/pages/blog/utils';
import {formatDateString} from '@/utils/server/blog/utils';

export const getArticleTags = (
  tags: string[],
  topics: Topics[],
  preferedTag = '',
) => {
  if (!preferedTag) {
    const articleTopics = tags?.filter((tag) => tag?.includes('topic:'));
    return articleTopics
      ?.map((topic) => {
        const topicSlug = topic?.replace('topic:', '');

        return topics?.filter(
          (currentTopic) => currentTopic?.handle === topicSlug,
        )[0]?.title;
      })
      ?.filter(Boolean);
  } else {
    return [
      topics?.filter((currentTopic) => currentTopic?.handle === preferedTag)[0]
        ?.title,
    ];
  }
};

export const getMetafieldAuthors = (
  metafields: ArticleMetafieldBlog[],
  defaultAuthorName?: string,
): Record<string, string>[] => {
  const authorData =
    metafields?.filter((metafield) => metafield?.key === 'authors')[0]?.value ||
    [];

  if (!authorData.length) {
    return [{name: defaultAuthorName || '', slug: ''}];
  }

  return authorData as Record<string, string>[];
};

export const getTopicHandle = (
  tags: string[],
  topics: Topics[],
  preferedTag = '',
) => {
  const articleTopics = getArticleTags(tags, topics, preferedTag);
  const topicName = filterArticleTag(articleTopics);

  return topics.find((topic: Topics) => topic.title === topicName)?.handle;
};

export const adaptSectionData = (
  articles: Article[],
  topics: Topics[],
  site: Site,
  preferedTag = '',
) => {
  return articles?.map((article) => {
    const authorData = article?.metafields?.find(
      (metafield) => metafield?.key === 'authors',
    ) as ArticleMetafield;

    const publishedDate = article?.metafields?.find(
      (metafield) => metafield?.key === 'published_date',
    );

    const authors = authorData?.references?.edges.map(
      (item: MetafieldEdges) => ({
        name: item.node.field.value,
        slug: item.node.handle,
      }),
    );

    return {
      ...article,
      tags: getArticleTags(article?.tags, topics, preferedTag),
      publishedAt: formatDateString(
        (publishedDate?.value as string) || article.publishedAt,
        site.locale,
      ),
      modifiedAt: formatDateString(
        (publishedDate?.value as string) || article.modifiedAt,
        site.locale,
      ),
      metafields: [publishedDate, {...authorData, value: authors}],
      topicHandle: getTopicHandle(article?.tags, topics, preferedTag),
    };
  });
};

export function parseAnchorLinks(contentDom: cheerio.CheerioAPI) {
  const toc = contentDom('.anchored-section')
    .toArray()
    .map((item) => {
      const domItem = contentDom(item || '');
      // make sure ancored link to FAQ is added to links list
      const domItemNextSiblimgFAQ = contentDom(item).find(
        '+ div.marketing-block > div',
      );

      if (domItem.next().is('h2') || domItem.next().is('h3')) {
        return {
          href: `#${item.attribs.id}`,
          text: domItem.next().text(),
        };
      }

      if (domItemNextSiblimgFAQ.has('> h2')) {
        return {
          href: `#${item.attribs.id}`,
          text: domItemNextSiblimgFAQ.find('> h2').text(),
        };
      }
      return null;
    });

  if (!toc.length) {
    return contentDom('#toc li')
      .toArray()
      .map((item) => {
        const domItem = contentDom(item || '');

        const domItem2 = contentDom(item.children || '');

        return {
          href: domItem2.attr('href') || '',
          text: domItem.text(),
        };
      });
  }

  return toc.filter((link) => link !== null && link!) as {
    href: string;
    text: string;
  }[];
}

export interface processArticleDataProps {
  requestData: ReturnType<typeof loadRequestData> extends Promise<infer R>
    ? R
    : never;
  article: any;
  latestArticlesData: any;
  site: Site;
  settings: string;
  additionalTransformations?: Array<
    (dom: cheerio.CheerioAPI) => cheerio.CheerioAPI
  >;
  topics: string;
  popularArticles: string | null;
  blogType?: BLOG_PAGE_TYPE;
  interlinkings?: string;
  context: AppLoadContext;
}
